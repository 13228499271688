.btn-close {
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTMuNDA5OSAxMi4wMDAyTDE5LjcwOTkgNS43MTAxOUMxOS44OTgyIDUuNTIxODggMjAuMDA0IDUuMjY2NDkgMjAuMDA0IDUuMDAwMTlDMjAuMDA0IDQuNzMzODggMTkuODk4MiA0LjQ3ODQ5IDE5LjcwOTkgNC4yOTAxOUMxOS41MjE2IDQuMTAxODggMTkuMjY2MiAzLjk5NjA5IDE4Ljk5OTkgMy45OTYwOUMxOC43MzM2IDMuOTk2MDkgMTguNDc4MiA0LjEwMTg4IDE4LjI4OTkgNC4yOTAxOUwxMS45OTk5IDEwLjU5MDJMNS43MDk5NCA0LjI5MDE5QzUuNTIxNjQgNC4xMDE4OCA1LjI2NjI0IDMuOTk2MDkgNC45OTk5NCAzLjk5NjA5QzQuNzMzNjQgMy45OTYwOSA0LjQ3ODI0IDQuMTAxODggNC4yODk5NCA0LjI5MDE5QzQuMTAxNjQgNC40Nzg0OSAzLjk5NTg1IDQuNzMzODggMy45OTU4NSA1LjAwMDE5QzMuOTk1ODUgNS4yNjY0OSA0LjEwMTY0IDUuNTIxODggNC4yODk5NCA1LjcxMDE5TDEwLjU4OTkgMTIuMDAwMkw0LjI4OTk0IDE4LjI5MDJDNC4xOTYyMSAxOC4zODMxIDQuMTIxODIgMTguNDkzNyA0LjA3MTA1IDE4LjYxNTZDNC4wMjAyOCAxOC43Mzc1IDMuOTk0MTQgMTguODY4MiAzLjk5NDE0IDE5LjAwMDJDMy45OTQxNCAxOS4xMzIyIDQuMDIwMjggMTkuMjYyOSA0LjA3MTA1IDE5LjM4NDhDNC4xMjE4MiAxOS41MDY2IDQuMTk2MjEgMTkuNjE3MiA0LjI4OTk0IDE5LjcxMDJDNC4zODI5IDE5LjgwMzkgNC40OTM1IDE5Ljg3ODMgNC42MTUzNiAxOS45MjkxQzQuNzM3MjIgMTkuOTc5OCA0Ljg2NzkzIDIwLjAwNiA0Ljk5OTk0IDIwLjAwNkM1LjEzMTk1IDIwLjAwNiA1LjI2MjY2IDE5Ljk3OTggNS4zODQ1MiAxOS45MjkxQzUuNTA2MzggMTkuODc4MyA1LjYxNjk4IDE5LjgwMzkgNS43MDk5NCAxOS43MTAyTDExLjk5OTkgMTMuNDEwMkwxOC4yODk5IDE5LjcxMDJDMTguMzgyOSAxOS44MDM5IDE4LjQ5MzUgMTkuODc4MyAxOC42MTU0IDE5LjkyOTFDMTguNzM3MiAxOS45Nzk4IDE4Ljg2NzkgMjAuMDA2IDE4Ljk5OTkgMjAuMDA2QzE5LjEzMiAyMC4wMDYgMTkuMjYyNyAxOS45Nzk4IDE5LjM4NDUgMTkuOTI5MUMxOS41MDY0IDE5Ljg3ODMgMTkuNjE3IDE5LjgwMzkgMTkuNzA5OSAxOS43MTAyQzE5LjgwMzcgMTkuNjE3MiAxOS44NzgxIDE5LjUwNjYgMTkuOTI4OCAxOS4zODQ4QzE5Ljk3OTYgMTkuMjYyOSAyMC4wMDU3IDE5LjEzMjIgMjAuMDA1NyAxOS4wMDAyQzIwLjAwNTcgMTguODY4MiAxOS45Nzk2IDE4LjczNzUgMTkuOTI4OCAxOC42MTU2QzE5Ljg3ODEgMTguNDkzNyAxOS44MDM3IDE4LjM4MzEgMTkuNzA5OSAxOC4yOTAyTDEzLjQwOTkgMTIuMDAwMlonIGZpbGw9JyMwOTEwMUQnLz48L3N2Zz4K");
}
.alert {
  padding: 16px;
  text-align: center;

  &-warning {
    --bs-alert-color: #8E4B10;
    --bs-alert-bg: #FDF6B2;
    --bs-alert-border-color: transparent;
    --bs-alert-link-color: #8E4B10;
  }
  &-danger {
    --bs-alert-color: #C81E1E;
    --bs-alert-bg: #FDE8E8;
    --bs-alert-border-color: transparent;
    --bs-alert-link-color: #C81E1E;
  }

  .btn-close {
    top: 50%;
    margin-top: -13px;
    padding: 5px 10px;
  }

  > p {
    margin: 0;
    font-size: 12px;

    & > p:last-child {
      margin: 0;
    }
  }

  .h4 {
    margin: 0 0 8px;
    font-size: 16px;
  }

  &.to-left {
    text-align: left;
  }

  &.to-center {
    text-align: center;
  }

  &.to-right {
    text-align: right;
  }
}