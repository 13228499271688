.select-box {
  font-family: "Fira Sans", sans-serif;
  margin: 0;
  padding: 0 14px;
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  height: 42px;
  border-radius: 4px;
  outline: none;
  background-color: #F4F6F9;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMDAwMiA5LjE3MDE5QzE2LjgxMjggOC45ODM5NCAxNi41NTk0IDguODc5MzkgMTYuMjk1MiA4Ljg3OTM5QzE2LjAzMSA4Ljg3OTM5IDE1Ljc3NzYgOC45ODM5NCAxNS41OTAyIDkuMTcwMTlMMTIuMDAwMiAxMi43MTAyTDguNDYwMTkgOS4xNzAxOUM4LjI3MjgzIDguOTgzOTQgOC4wMTkzOCA4Ljg3OTM5IDcuNzU1MTkgOC44NzkzOUM3LjQ5MTAxIDguODc5MzkgNy4yMzc1NiA4Ljk4Mzk0IDcuMDUwMTkgOS4xNzAxOUM2Ljk1NjQ2IDkuMjYzMTUgNi44ODIwNyA5LjM3Mzc1IDYuODMxMyA5LjQ5NTYxQzYuNzgwNTMgOS42MTc0NyA2Ljc1NDM5IDkuNzQ4MTggNi43NTQzOSA5Ljg4MDE5QzYuNzU0MzkgMTAuMDEyMiA2Ljc4MDUzIDEwLjE0MjkgNi44MzEzIDEwLjI2NDhDNi44ODIwNyAxMC4zODY2IDYuOTU2NDYgMTAuNDk3MiA3LjA1MDE5IDEwLjU5MDJMMTEuMjkwMiAxNC44MzAyQzExLjM4MzIgMTQuOTIzOSAxMS40OTM4IDE0Ljk5ODMgMTEuNjE1NiAxNS4wNDkxQzExLjczNzUgMTUuMDk5OCAxMS44NjgyIDE1LjEyNiAxMi4wMDAyIDE1LjEyNkMxMi4xMzIyIDE1LjEyNiAxMi4yNjI5IDE1LjA5OTggMTIuMzg0OCAxNS4wNDkxQzEyLjUwNjYgMTQuOTk4MyAxMi42MTcyIDE0LjkyMzkgMTIuNzEwMiAxNC44MzAyTDE3LjAwMDIgMTAuNTkwMkMxNy4wOTM5IDEwLjQ5NzIgMTcuMTY4MyAxMC4zODY2IDE3LjIxOTEgMTAuMjY0OEMxNy4yNjk5IDEwLjE0MjkgMTcuMjk2IDEwLjAxMjIgMTcuMjk2IDkuODgwMTlDMTcuMjk2IDkuNzQ4MTggMTcuMjY5OSA5LjYxNzQ3IDE3LjIxOTEgOS40OTU2MUMxNy4xNjgzIDkuMzczNzUgMTcuMDkzOSA5LjI2MzE1IDE3LjAwMDIgOS4xNzAxOVonIGZpbGw9JyMwOTEwMUQnLz48L3N2Zz4K");
  background-position: 99% 50%;
  background-repeat: no-repeat;

  &:focus {
    border: 1px solid #111111;
  }
}

.autocomplete-list {
  max-height: 180px;
  overflow-y: auto;
}