.qr-code {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
}

.qr-scanner {
  border: 1px solid #dddddd;
  background-color: #eeeeee;
}

.qr-reader {
  width: 100%;
  // padding-top: 100%;
  overflow: hidden;
  position: relative;

  video {
    width: 100%;
    display: block;
    overflow: hidden;
    transform: scaleX(-1);
    background: linear-gradient(45deg, #2e0440, #16063a);
    aspect-ratio: 4 / 3;
    border-radius: 8px;
    object-fit: cover;
  }

  .view-finder {
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    border: 50px solid rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.scan-region-highlight {
  position: absolute;
  pointer-events: none;
  transform: scaleX(-1);
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  box-sizing: border-box;

  .scan-region-highlight-svg {
    position: relative;
    height: 100%;
    left: 50%;
    top: 0;
    fill: none;
    stroke: rgba(255, 255, 255, .5);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    aspect-ratio: 1 / 1;
    display: block;
    transform: translate(-50%, 0);
  }

  .code-outline-highlight {
    display: none;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #e9b213;
    stroke-width: 5;
    stroke-dasharray: 25;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}