.btn {
  width: 100%;
  height: 44px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  box-sizing: border-box;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  flex-grow: 1;

  &-half {
    width: calc(50% - 10px);
    box-sizing: border-box;

    &:first-child {
      margin: 0 10px 0 0;
    }

    &:last-child {
      margin: 0 0 0 10px;
    }
  }

  &-fixed {
    width: auto;
    position: fixed;
    bottom: 10px;
    left: 20px;
    right: 20px;
  }

  &-full {
    width: 100%;
    min-width: 130px;
  }

  &-select {
    margin: 0;
    padding: 0 24px 0 14px;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    height: 46px;
    border-radius: 4px;
    outline: none;
    background-color: #F4F6F9;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMDAwMiA5LjE3MDE5QzE2LjgxMjggOC45ODM5NCAxNi41NTk0IDguODc5MzkgMTYuMjk1MiA4Ljg3OTM5QzE2LjAzMSA4Ljg3OTM5IDE1Ljc3NzYgOC45ODM5NCAxNS41OTAyIDkuMTcwMTlMMTIuMDAwMiAxMi43MTAyTDguNDYwMTkgOS4xNzAxOUM4LjI3MjgzIDguOTgzOTQgOC4wMTkzOCA4Ljg3OTM5IDcuNzU1MTkgOC44NzkzOUM3LjQ5MTAxIDguODc5MzkgNy4yMzc1NiA4Ljk4Mzk0IDcuMDUwMTkgOS4xNzAxOUM2Ljk1NjQ2IDkuMjYzMTUgNi44ODIwNyA5LjM3Mzc1IDYuODMxMyA5LjQ5NTYxQzYuNzgwNTMgOS42MTc0NyA2Ljc1NDM5IDkuNzQ4MTggNi43NTQzOSA5Ljg4MDE5QzYuNzU0MzkgMTAuMDEyMiA2Ljc4MDUzIDEwLjE0MjkgNi44MzEzIDEwLjI2NDhDNi44ODIwNyAxMC4zODY2IDYuOTU2NDYgMTAuNDk3MiA3LjA1MDE5IDEwLjU5MDJMMTEuMjkwMiAxNC44MzAyQzExLjM4MzIgMTQuOTIzOSAxMS40OTM4IDE0Ljk5ODMgMTEuNjE1NiAxNS4wNDkxQzExLjczNzUgMTUuMDk5OCAxMS44NjgyIDE1LjEyNiAxMi4wMDAyIDE1LjEyNkMxMi4xMzIyIDE1LjEyNiAxMi4yNjI5IDE1LjA5OTggMTIuMzg0OCAxNS4wNDkxQzEyLjUwNjYgMTQuOTk4MyAxMi42MTcyIDE0LjkyMzkgMTIuNzEwMiAxNC44MzAyTDE3LjAwMDIgMTAuNTkwMkMxNy4wOTM5IDEwLjQ5NzIgMTcuMTY4MyAxMC4zODY2IDE3LjIxOTEgMTAuMjY0OEMxNy4yNjk5IDEwLjE0MjkgMTcuMjk2IDEwLjAxMjIgMTcuMjk2IDkuODgwMTlDMTcuMjk2IDkuNzQ4MTggMTcuMjY5OSA5LjYxNzQ3IDE3LjIxOTEgOS40OTU2MUMxNy4xNjgzIDkuMzczNzUgMTcuMDkzOSA5LjI2MzE1IDE3LjAwMDIgOS4xNzAxOVonIGZpbGw9JyMwOTEwMUQnLz48L3N2Zz4K");
    background-position: 99% 50%;
    background-repeat: no-repeat;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    &:hover {
      background-color: #F4F6F9;
    }
  }

  &.price-button {
    background: #F4F6F9;
    min-width: auto;
    border: none !important;
    position: relative;
    z-index: 2;

    &:focus, &:hover {
        background: #e9ecf1;
    }
  }

  &-auto {
    width: auto;
  }

  &-dark {
    background: #09101D;
    color: #fff;
  }

  &-light {
    color: #09101D;
    background: #fff;
    border: 2px solid #09101D !important;
  }

  &-row-center {
    justify-content: center;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.btn-checkbox {
    width: auto;
  }

  .icon {
    padding: 0;
    width: 24px;
    height: 24px;
    margin-right: 10px;

    &:last-child {
      margin-right: inherit;
    }
  }

  &.btn-10vw {
    max-width: 10vw;
  }
  &.btn-20vw {
    max-width: 20vw;
  }
  &.btn-30vw {
    max-width: 30vw;
  }
  &.btn-40vw {
    max-width: 40vw;
  }
  &.btn-50vw {
    max-width: 50vw;
  }
  &.btn-60vw {
    max-width: 60vw;
  }
  &.btn-70vw {
    max-width: 70vw;
  }
  &.btn-80vw {
    max-width: 80vw;
  }
  &.btn-90vw {
    max-width: 90vw;
  }
  &.btn-100vw {
    max-width: 100vw;
  }
}

.button-panel {
  padding: 0;
  margin: 16px 0;
  
  & > .bp-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-center > .bp-row {
    justify-content: center;
  }
}

