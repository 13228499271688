.checkbox-item {
  font-size: 14px;
  display: block;
  position: relative;

  input {
    // margin-right: 10px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    background: #fff;
    border: 1px solid #777;
    height: 14px;
    width: 14px;
    border-radius: 100%;
    vertical-align: text-bottom;
    position: relative;
  }

  /* Remove the circular shape from checkboxes */
  input[type="checkbox"] {
    border-radius: 0;
  }

  /* Styles for the pseudo-elements */
  input[type="radio"]::before,
  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    top: 0;
  }

  /* Center of the checked radio button */
  input[type="radio"]:checked::before {
    border: 2px solid transparent;
    border-radius: 100%;
    background: hotpink;
    margin: 4px;
  }

  /* The checkmark shape */
  input[type="checkbox"]:checked::before {
    border-right: 2px solid hotpink;
    border-bottom: 2px solid hotpink;
    height: 90%;
    width: 30%;
    transform: rotate(50deg) translateY(-20%) translateX(-10%);
  }

  /* Some focus styles for accessibility */
  // input[type="radio"]:focus,
  // input[type="checkbox"]:focus {
  //   outline: solid 1px;
  //   outline-offset: 2px;
  // }
}

.checkbox-result.checked .icon {
  fill: green;
}

.checkbox-group {
  padding: 10px 0;
  display: flex;
  white-space: nowrap;
  overflow: auto;

  .checkbox-toggle {
    border-radius: 96px;
    background-color: #f4f6f9;
    color: #09101D;
    padding: 6px 14px;
    margin-bottom: 6px;
    margin-right: 6px;
    display: inline-block;
    word-break: keep-all;
    word-wrap: normal;
    font-weight: 500;
    font-size: 14px;

    &.selected {
      background-color: #000000;
      color: white;
    }

    &:last-child {
      margin-right: 0;
    }

    input {
      visibility: hidden;
      position: absolute;
      left: 0;
    }

    &:has(input:checked) {
      background-color: #000000;
      color: white;
    }
  }
}

.slider-checkbox {
  position: relative;
  min-height: 34px;
  display: inline-block;
  margin: 10px 0 0;

  input {
    margin: 1px 0 0 0;
    cursor: pointer;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: red;
    width: 50px;
    height: 32px;

    &:checked + .slider-checkbox-label {
      &:before {
        background-color: #76C875;
        content: "";
        padding-left: 6px;
      }
      &:after {
        left: 20px;
      }
    }
  }
  .slider-checkbox-label {
    position: relative;
    padding-left: 60px;
    min-height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &:before, &:after {
      position: absolute;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      transition: background-color 0.3s, left 0.3s;
    }
    &:before {
      content: "";
      color: #fff;
      box-sizing: border-box;
      font-family: 'FontAwesome', sans-serif;
      padding-left: 23px;
      font-size: 12px;
      line-height: 32px;
      background-color: #EBEEF2;
      left: 0;
      top: 0;
      height: 32px;
      width: 50px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
    }
    &:after {
      content: "";
      letter-spacing: 20px;
      background: #fff;
      left: 2px;
      top: 2px;
      height: 28px;
      width: 28px;
    }
  }
}

.alert-note {
  text-align: right;
  color: red;
  font-size: 80%;
  font-weight: bold;
  right: 0;
  top: 3px;
  position: absolute;
}

.ready-note {
  text-align: right;
  color: green;
  font-size: 80%;
  font-weight: bold;
  right: 0;
  top: 3px;
  position: absolute;
}

.input-group-text {
  border: none !important;
  background: transparent;
  padding: 0 10px 0 4px;
}

.field {
  color: #111111;
  width: 100%;
  margin: 0 0 16px;
  position: relative;

  &-note {
    float: right;
  }

  &-label {
    font-weight: 700;
    font-size: 14px;
    padding: 0 0 6px;
    display: block;
    text-align: left;
    line-height: normal;
    vertical-align: top;
  }

  & > .info-field-data {
    font-weight: normal;
  }

  & > .info-field-data > input:not([type="checkbox"]),
  & > .info-field-data > input:not([type="radio"]),
  & > .info-field-data > textarea,
  & > .info-field-data > select, .select {
    font-family: "Fira Sans", sans-serif;
    margin: 0;
    padding: 0 24px 0 14px;
    border: none;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    height: 46px;
    border-radius: 4px;
    outline: none;
    background-color: #F4F6F9;


    &:focus {
      border: 1px solid #111111;
    }
  }
  & > .info-field-data > select, .select {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMDAwMiA5LjE3MDE5QzE2LjgxMjggOC45ODM5NCAxNi41NTk0IDguODc5MzkgMTYuMjk1MiA4Ljg3OTM5QzE2LjAzMSA4Ljg3OTM5IDE1Ljc3NzYgOC45ODM5NCAxNS41OTAyIDkuMTcwMTlMMTIuMDAwMiAxMi43MTAyTDguNDYwMTkgOS4xNzAxOUM4LjI3MjgzIDguOTgzOTQgOC4wMTkzOCA4Ljg3OTM5IDcuNzU1MTkgOC44NzkzOUM3LjQ5MTAxIDguODc5MzkgNy4yMzc1NiA4Ljk4Mzk0IDcuMDUwMTkgOS4xNzAxOUM2Ljk1NjQ2IDkuMjYzMTUgNi44ODIwNyA5LjM3Mzc1IDYuODMxMyA5LjQ5NTYxQzYuNzgwNTMgOS42MTc0NyA2Ljc1NDM5IDkuNzQ4MTggNi43NTQzOSA5Ljg4MDE5QzYuNzU0MzkgMTAuMDEyMiA2Ljc4MDUzIDEwLjE0MjkgNi44MzEzIDEwLjI2NDhDNi44ODIwNyAxMC4zODY2IDYuOTU2NDYgMTAuNDk3MiA3LjA1MDE5IDEwLjU5MDJMMTEuMjkwMiAxNC44MzAyQzExLjM4MzIgMTQuOTIzOSAxMS40OTM4IDE0Ljk5ODMgMTEuNjE1NiAxNS4wNDkxQzExLjczNzUgMTUuMDk5OCAxMS44NjgyIDE1LjEyNiAxMi4wMDAyIDE1LjEyNkMxMi4xMzIyIDE1LjEyNiAxMi4yNjI5IDE1LjA5OTggMTIuMzg0OCAxNS4wNDkxQzEyLjUwNjYgMTQuOTk4MyAxMi42MTcyIDE0LjkyMzkgMTIuNzEwMiAxNC44MzAyTDE3LjAwMDIgMTAuNTkwMkMxNy4wOTM5IDEwLjQ5NzIgMTcuMTY4MyAxMC4zODY2IDE3LjIxOTEgMTAuMjY0OEMxNy4yNjk5IDEwLjE0MjkgMTcuMjk2IDEwLjAxMjIgMTcuMjk2IDkuODgwMTlDMTcuMjk2IDkuNzQ4MTggMTcuMjY5OSA5LjYxNzQ3IDE3LjIxOTEgOS40OTU2MUMxNy4xNjgzIDkuMzczNzUgMTcuMDkzOSA5LjI2MzE1IDE3LjAwMDIgOS4xNzAxOVonIGZpbGw9JyMwOTEwMUQnLz48L3N2Zz4K");
    background-position: 99% 50%;
    background-repeat: no-repeat;

    &:focus {
      border: 1px solid #111111;
    }
  }

  & > .info-field-data > textarea {
    height: auto;
  }

  & > .info-field-data > input:not([type="checkbox"]):not(.form-control),
  & > .info-field-data > input:not([type="radio"]):not(.form-control),
  & > .info-field-data > textarea,
  & > .info-field-data > select {
    width: 100%;
  }

  & > .form-control {
    background-color: #EEEEEE;
    width: auto;
  }
}


.sugesstion-auto {
  position: relative;
  flex-grow: 1;

  input {
    width: 100%;
  }

  .suggestions {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 999;
    cursor: pointer;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    background-color: white;
    border: 1px solid #777;
  }  

  .suggestions > div {
    line-height: 24px;
    padding: 4px;
    box-sizing: border-box;
    border-bottom: 1px solid #777;
  }

  .suggestions > div:hover {
    background-color: #f1f1f1;
    border-color: #777;
  }

  .suggestions > div.selected {
    background-color: #f1f1f1;
    border-color: #777;
  }

  .dropdown {
    display: block;
  }

  .dropdown,
  .dropdown-content {
    width: 100%;
  }
}

.date-period {
  line-height: 24px;
}

.input {
  width: 100%;
}

.input-file {
	position: relative;
	display: inline-block;

  span {
    position: relative;
    display: inline-block;
  }

  input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
}

.input {
  margin: 0;
  color: #111111;

  &-label {
    font-weight: 700;
    font-size: 15px;
    padding: 0 0 8px;
    display: block;
    text-align: left;
  }

  input:not([type="checkbox"]),
  input:not([type="radio"]),
  textarea,
  select {
    font-family: "Fira Sans", sans-serif;
    margin: 0;
    padding: 0 14px;
    border: none;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    height: 46px;
    border-radius: 4px;
    outline: none;
    background-color: #F4F6F9;

    &:focus {
      border: 1px solid #111111;
    }
  }

  .form-control {
    background-color: #F4F6F9;
  }
}

.input-group {
  margin: 0 0 2px;
  background-color: #F4F6F9;

  input:not([type="checkbox"]),
  input:not([type="radio"]),
  select {
    background-color: #F4F6F9;
    border: none;
    height: 46px;
  }

  > .form-control,
  > .form-select,
  > .form-floating {
    background-color: #F4F6F9;
    border: none;
    height: 46px;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &.date-period {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTcuMDAwMiA5LjE3MDE5QzE2LjgxMjggOC45ODM5NCAxNi41NTk0IDguODc5MzkgMTYuMjk1MiA4Ljg3OTM5QzE2LjAzMSA4Ljg3OTM5IDE1Ljc3NzYgOC45ODM5NCAxNS41OTAyIDkuMTcwMTlMMTIuMDAwMiAxMi43MTAyTDguNDYwMTkgOS4xNzAxOUM4LjI3MjgzIDguOTgzOTQgOC4wMTkzOCA4Ljg3OTM5IDcuNzU1MTkgOC44NzkzOUM3LjQ5MTAxIDguODc5MzkgNy4yMzc1NiA4Ljk4Mzk0IDcuMDUwMTkgOS4xNzAxOUM2Ljk1NjQ2IDkuMjYzMTUgNi44ODIwNyA5LjM3Mzc1IDYuODMxMyA5LjQ5NTYxQzYuNzgwNTMgOS42MTc0NyA2Ljc1NDM5IDkuNzQ4MTggNi43NTQzOSA5Ljg4MDE5QzYuNzU0MzkgMTAuMDEyMiA2Ljc4MDUzIDEwLjE0MjkgNi44MzEzIDEwLjI2NDhDNi44ODIwNyAxMC4zODY2IDYuOTU2NDYgMTAuNDk3MiA3LjA1MDE5IDEwLjU5MDJMMTEuMjkwMiAxNC44MzAyQzExLjM4MzIgMTQuOTIzOSAxMS40OTM4IDE0Ljk5ODMgMTEuNjE1NiAxNS4wNDkxQzExLjczNzUgMTUuMDk5OCAxMS44NjgyIDE1LjEyNiAxMi4wMDAyIDE1LjEyNkMxMi4xMzIyIDE1LjEyNiAxMi4yNjI5IDE1LjA5OTggMTIuMzg0OCAxNS4wNDkxQzEyLjUwNjYgMTQuOTk4MyAxMi42MTcyIDE0LjkyMzkgMTIuNzEwMiAxNC44MzAyTDE3LjAwMDIgMTAuNTkwMkMxNy4wOTM5IDEwLjQ5NzIgMTcuMTY4MyAxMC4zODY2IDE3LjIxOTEgMTAuMjY0OEMxNy4yNjk5IDEwLjE0MjkgMTcuMjk2IDEwLjAxMjIgMTcuMjk2IDkuODgwMTlDMTcuMjk2IDkuNzQ4MTggMTcuMjY5OSA5LjYxNzQ3IDE3LjIxOTEgOS40OTU2MUMxNy4xNjgzIDkuMzczNzUgMTcuMDkzOSA5LjI2MzE1IDE3LjAwMDIgOS4xNzAxOVonIGZpbGw9JyMwOTEwMUQnLz48L3N2Zz4K");
      background-position: 99% 50%;
      background-repeat: no-repeat;
    }
   }
 > .form-control:focus,
  > .form-select:focus,
  > .form-floating:focus-within {

  }
}

.input-icon {
  display: inline-flex;
  margin: 0 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #14224A;
  background: white;
  padding: 0 5px;

  &-label {
    font-weight: 500;
    font-size: 15px;
    padding: 0 0 8px;
    display: block;
    text-align: left;
  }
  &:focus-within {
    border: 1px solid #111111;
  }

  input:not([type="checkbox"]),
  input:not([type="radio"]),
  input:not([autocomplete="one-time-code"]),
  select {
    font-family: "Fira Sans", sans-serif;
    margin: 0;
    padding: 0 14px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    height: 42px;
    outline: none;
    border: 0;
  }

  div {
    display: flex;
    height: 100%;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin: auto;
  }
}

.input-group svg.icon {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: auto;
  box-sizing: border-box;
}

.input-text {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #14224A;
  margin-bottom: 10px;
  background: white;
  padding: 0 5px;
  font-size: 14px;
}

.error-hint {
  font-size: 80%;
  font-weight: bold;
  margin-top: -2px;
}

.warning-hint {
  font-size: 80%;
  margin-top: -2px;
}

.input-field {
  position: relative;

  & + .warning-hint {
    margin-top: 4px;
  }
}

.input-note {
  position: absolute;
  font-size: 90%;
  font-weight: normal;
  right: 0;
  top: -60%;
}

.checkbox {

  input {
    display: none;

    & + label {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px solid #C0C0CF;
      transition: all .2s;
      position: relative;
      background: #fff;

      &:after {
        content: "";
        width: 9px;
        height: 6px;
        border-bottom: 2px solid #333;
        border-left: 2px solid #333;
        transition: all 0.2s;
        transform: rotate(-45deg);
        display: block;
        position: absolute;
        left: 5px;
        top: 5px;
        opacity: 0;
      }
    }

    &:checked + label {
      border: 1px solid #333;

      &:after {
        opacity: 1;
      }
    }
  }

}