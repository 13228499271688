.rdp-day {
  // color: grey;
  font-weight: bold;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;

  .calendar-day {
    display: flex;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: normal;

    .calendar-date {
      display: block;
      height: 20px;
    }

    .calendar-seat {
      display: block;
      height: 15px;
      font-size: 10px;
      font-weight: bold;
      color: #049c18;
    }

    &.have-seats {
      .calendar-date {
        font-weight: bold;
        color: black;
      }
    }
  }

  &.rdp-day_selected:not(.rdp-day_range_middle) {
    background-color: #09101d;
    color: #fff;

    .have-seats {
      .calendar-date {
        font-weight: bold;
        color: white;
      }

      .calendar-seat {
        color: white;
      }
    }
  }

  &.rdp-day_today {
    border: 2px solid #09101d;
  }
}

.calendar-available-time {
  margin-right: 10px;
  margin-bottom: 10px;
  // background-color: lightgrey;
  border-radius: 8px;
  padding: 4px;
  display: inline-block;
  border: none;
}

.calendar-available-time.selected {
  // background-color: #111111;
  color: white;
}

.calendar-day-list {
  display: flex;
  padding: 0;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin-top: 1.2rem;

  .calendar-day {
    border-radius: 15px;
    border: none;
    background: #F4F6F9;
    margin: 2px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 0 0 52px;
    height: 52px;
    scroll-snap-align: start;
    transition: all 0.2s;
    flex-grow: 1;
    justify-content: center;

    .calendar-day:not(:last-child) {
      margin-right: 4px;
    }

    .day-date {
      display: block;
      font-size: 16px;
      color: #09101D;
      font-weight: 800;
      line-height: 18px;
    }

    .day-name {
      display: block;
      font-size: 12px;
      color: #747B84;
      line-height: 16px;

      &.weekend {
        color: #E24949;
      }
    }

    &.selected {
      font-weight: bold;
      background-color: #09101D;

      .day-date {
        color: #fff;
      }

      .day-name {
        color: #fff;
      }
    }
  }

  .have-excursion {
    background-color: #60a569;
  }

  .selected.have-excursion {
    background-color: #049c18;
  }
}

.rdp {
  margin: auto;
  width: 100%;
}
.rdp-months {
  width: 100%;
}
.rdp-month {
  width: 100%;
}
.rdp-table {
  max-width: 100%;
  width: 100%;
}
.rdp:not([dir=rtl]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
  .rdp:not([dir=rtl]) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

.rdp-day_range_middle {
  background-color: #F4F6F9;
  color: #000000;
  border-radius: 0;
}
.rdp-day_range_end.rdp-day_range_start {
  border-radius: 8px;
}