.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;
}

.stepper-item:first-child,
.stepper-item:last-child {
  flex: 1;
}

.stepper-item:first-child {
  align-items: flex-start;
}

.stepper-item:last-child {
  align-items: flex-end;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #F4F6F9;
  width: 100%;
  top: 6px;
  left: -50%;
  z-index: 2;
}

.stepper-item:last-child::before {
  left: -100%;
  width: 200%;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #F4F6F9;
  width: 100%;
  top: 6px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
  margin-top: 4px;
  border-radius: 50%;
  background: #CED4DD;
  margin-bottom: 6px;
}

.stepper-item.current .step-counter {
  width: 10px;
  height: 10px;
  margin-top: 3px;
  background-color: #4bb543;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active .step-counter {
  background-color: #4bb543;
}

.stepper-item.active::before {
  border-bottom: 4px solid #4bb543;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::before {
  border-bottom: 4px solid #4bb543;
}

.stepper-item.completed:last-child::after {
  border-bottom: 4px solid #CED4DD;
}

.stepper-item.current::before {
  border-bottom: 4px solid #4bb543;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.stepper-item .step-name {
  font-size: 12px;
}

.stepper-current-name {
  font-size: 12px;
  font-weight: 700;
  padding: 0 0 4px;
}