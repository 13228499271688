@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Fira Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

@import "debug";
@import "~bootstrap/scss/bootstrap";
@import "react-day-picker/dist/style.css";
@import "react-toastify/dist/ReactToastify.css";
@import "app";
@import "dashboard";
@import "header";
@import "footer";
@import "button";
@import "link";
@import "stepper";
@import "stepper2";
@import "alert";
@import "tabs";
@import "modal";
@import "one_time_code";
@import "navbar";
@import "profile";
@import "calendar";
@import "icon";
@import "input";
@import "list";
@import "info";
@import "table";
@import "badge";
@import "qrcode";
@import "section";
@import "select";
@import "dropdown";