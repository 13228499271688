.trip-app {
  &-footer {
    font-weight: 700;
    background: #fff;
    display: block;
    flex-direction: column;
    font-size: 14px;
    color: black;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 10px;

    &.center {
      align-items: center;
      justify-content: center;
    }

    &.error {

    }
  }
}