.badge {
  padding: 6px 8px;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  height: 24px;
  line-height: 12px;
  vertical-align: baseline;
  width: fit-content;
  font-weight: 500;

  &.badge-success {
    background: #DEF7EC;
    color: #03543F;
  }

  &.badge-primary {
    background: #8dc3ff;
    color: #00267d;
  }

  &.badge-secondary {
    background: #F2F2F2;
    color: #4F4F4F;
  }

  &.badge-danger {
    background: #ff0000;
    color: #fff;
  }

  &.badge-warning {
    background: #ff8800;
    color: #fff;
  }
}
