.search-icon {
  width: 38px;
  height: 38px;
  padding: 7px;
  fill: none;
  stroke: #020202;
  stroke-miterlimit: 10;
  stroke-width: 1.91px;
}

.filter-icon {
  width: 38px;
  height: 38px;
  padding: 7px;
  fill: #020202;
  // stroke: #020202;
  stroke-miterlimit: 10;
  stroke-width: 1.91px;
}

.input-group .icon {
  // background-color: #E0E0E0;
  border: none;
}

svg.icon {
  width: 38px;
  height: 38px;
  padding: 7px;
  margin: auto;
  box-sizing: border-box;
}

.icon.icon-disabled {
  fill: grey;
}