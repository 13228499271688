.trip-app {
  &-header {
    font-weight: 700;
    background: #fff;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: black;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 16px 10px;
    flex-wrap: wrap;

    .title {
      display: block;
      padding-left: 16px;
      flex: auto;

    }

    &-hide {

      .header {
        display: none;
      }
    }

    &.center {
      align-items: center;
      justify-content: center;
    }

    &.error {

    }
  }
}