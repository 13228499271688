.offcanvas {
  --bs-offcanvas-width: 300px!important;
}

.offcanvas-body {
  padding: 10px;
}

.nav-bar {
  top: 0;
  height: 100%;
  display: inline-block;
  z-index: 1000;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  width: 300px;
}

.nav-bar-header {
  height: 96px;
  border-bottom: 1px solid #999999;
  position: absolute;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}

.nav-bar-footer {
  height: 60px;
  border-top: 1px solid #999999;
  bottom: 0px;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
}

.nav-bar-body {
  overflow: auto;
  padding: 0;
  position: absolute;
  top: 96px;
  bottom: 60px;
  width: 100%;
  box-sizing: border-box;
}

.nav-bar-item, .nav-bar-group {
  overflow: auto;
  padding: 10px 16px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.78);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;

  &.current {
    background: #F4F6F9;
    font-weight: 600;
    color: #000000;
  }
}

.nav-bar-group-hide {
  display: none;
}

.nav-bar-group-show {
}

.nav-bar-group-selected {
}

.nav-bar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.nav-bar-overlay-open {
  opacity: 1;
  z-index: 0;
  visibility: visible;
}

.nav-bar-overlay-hidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.nav-bar-hidden {
  visibility: hidden;
  width: 240px;
}



.nav-bar-animate {
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
  0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.nav-bar-left {
  left: 0;
  right: auto;
}

.nav-bar-left.nav-bar-hidden {
  transform: translateX(-240px);
}

.nav-bar-right {
  right: 0;
  left: auto;
}

.nav-bar-right.nav-bar-hidden {
  transform: translateX(240px);
}

.nav-bar-profile-card {
  display: flex;
  padding: 0 0 10px 0;
  width: 100%;
  border-bottom: 4px solid #F4F6F9;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;

  &-avatar {
    display: flex;
    align-items: center;
  }

  &-image-middle {
    display: block;
    width: 64px;
    height: 64px;
  }

  &-image-middle img, &-image-middle svg.icon {
    width: 64px;
    height: 64px;
    padding: 0;
    vertical-align: top;
  }

  &-image {
    border-radius: 50%;
    background-color: #ffffff;
    display: block;
    width: 64px;
    height: 64px;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
  }

  &-info {
    width: 100%;
    padding: 0 0 2px 16px;
  }

  &-info-header {
    display: block;
    font-weight: bold;
    text-transform: capitalize;
    padding: 0 0 3px;
  }
  
  &-info-body {
    display: block;
    color: #5c6067;
    font-size: 12px;
    font-weight: 500;
  }

  &-info-footer {
    display: block;
    color: #5c6067;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}

.title-disabled {
  color: #777777;
}