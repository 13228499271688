.section {
  margin: 16px 0 24px;

  .title {
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px solid;
    line-height: normal;
    margin: 0 0 24px;
  }

  &.static .title {
    font-size: 14px;
    text-decoration: none;
  }

  &.hidden .section-body {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}