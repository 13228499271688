.profile-card {
}

.profile-card-image,
.profile-card-image.icon {
  border-radius: 50%;
  background-color: #ffffff;
  width: 128px;
  height: 128px;
  padding: 0;
  margin: 0;
}

.profile-approved.icon {
  fill: #00aa00;
  stroke: #00aa00;
  height: 16px;
  width: 16px;
  padding: 0;
  margin-right: 8px;
}
