.dropdown-btn {
  font-size: 16px;
  border-radius: 4px;
  font-weight: 600;
  box-sizing: border-box;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  padding: 6px 12px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: 'none';
  border: 1px solid #212529!important;
  cursor: pointer;
}

.dropdown-btn:hover, .dropdown-btn:focus {
  background-color: #d3d4d5;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%;
}

.dropdown.dropdown-right .dropdown-content {
  right: 0;
}

.dropdown.dropdown-left .dropdown-content {
  left: 0;
}

.dropdown.dropdown-right:first-child {
  margin-left: auto;
}

.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #fff;
  transition: all .2s;
}

.dropdown-content .dropdown-item:hover {
  background-color: #faffbb;
}

.dropdown-content.show {
  display: block;
}

.autocomplete-drop-icon.hide {
  display: none;
}