.trip-table {
  .table-row {
    padding: 5px 0;
  }

  .table-row.delim:not(:last-child) {
    padding: 10px 0 0;
    border-bottom: 4px solid #F4F6F9;
  }

  .table-row.sub-row {
    padding: 10px 0;
    border-top: 2px solid #efefef;
  }

  &.table-fix > .table-row > .table-col {
    // float: left;
    display: inline-block;
    vertical-align: top;
  }

  .table-cell {
    box-sizing: border-box;
  }

  .table-cell.align-left {
    text-align: left;
  }

  .table-cell.align-right {
    text-align: right;
  }

  .table-cell.align-center {
    text-align: center;
  }

  &.table-fix .table-row {
    // clear: both;
  }

  &.table-flex,
  &.table-flex > .table-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &.table-flex > .table-row > .table-col {
    display: flex;
    flex-grow: 1;
  }

  &.table-flex > .table-row.table-break {
    flex-wrap: wrap;
    flex-basis: 100%;
    height: 0;
    padding: 0;
  }

  &.table-flex > .table-row > .table-col.v-align-center {
    align-items: center;
  }
  &.table-flex > .table-row > .table-col.v-align-top {
    align-items: start;
  }
  &.table-flex > .table-row > .table-col.v-align-bottom {
    align-items: end;
  }

  &.table-cols-12 > .table-row > .table-col {
    width: 8.33333%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-2 {
    width: 16.66666%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-3 {
    width: 24.99999%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-4 {
    width: 33.33333%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-5 {
    width: 41.66666%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-6 {
    width: 48.99999%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-7 {
    width: 58.33333%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-8 {
    width: 66.66666%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-9 {
    width: 74.99999%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-10 {
    width: 83.33333%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-11 {
    width: 91.66666%;
  }
  &.table-cols-12 > .table-row > .table-col.colspan-12 {
    width: 99.99999%;
  }

  &.table-cols-11 > .table-row > .table-col {
    width: 9.09090%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-2 {
    width: 18.18181%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-3 {
    width: 27.27272%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-4 {
    width: 36.36363%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-5 {
    width: 45.45454%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-6 {
    width: 54.54545%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-7 {
    width: 63.63636%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-8 {
    width: 72.72727%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-9 {
    width: 81.81818%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-10 {
    width: 90.90909%;
  }
  &.table-cols-11 > .table-row > .table-col.colspan-11 {
    width: 99.99999%;
  }

  &.table-cols-10 > .table-row > .table-col {
    width: 9.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-2 {
    width: 19.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-3 {
    width: 29.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-4 {
    width: 39.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-5 {
    width: 49.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-6 {
    width: 59.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-7 {
    width: 69.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-8 {
    width: 79.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-9 {
    width: 89.99999%;
  }
  &.table-cols-10 > .table-row > .table-col.colspan-10 {
    width: 99.99999%;
  }

  &.table-cols-9 > .table-row > .table-col {
    width: 11.11111%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-2 {
    width: 22.22222%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-3 {
    width: 33.33333%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-4 {
    width: 44.44444%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-5 {
    width: 55.55555%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-6 {
    width: 66.66666%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-7 {
    width: 77.77777%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-8 {
    width: 88.88888%;
  }
  &.table-cols-9 > .table-row > .table-col.colspan-9 {
    width: 99.99999%;
  }

  &.table-cols-8 > .table-row > .table-col {
    width: 12.49999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-2 {
    width: 24.99999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-3 {
    width: 37.49999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-4 {
    width: 49.99999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-5 {
    width: 62.49999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-6 {
    width: 75.99999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-7 {
    width: 87.49999%;
  }
  &.table-cols-8 > .table-row > .table-col.colspan-8 {
    width: 99.99999%;
  }

  &.table-cols-7 > .table-row > .table-col {
    width: 14.28571%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-2 {
    width: 28.57142%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-3 {
    width: 42.85714%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-4 {
    width: 57.14285%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-5 {
    width: 71.42857%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-6 {
    width: 85.71428%;
  }
  &.table-cols-7 > .table-row > .table-col.colspan-7 {
    width: 99.99999%;
  }

  &.table-cols-6 > .table-row > .table-col {
    width: 16.66666%;
  }
  &.table-cols-6 > .table-row > .table-col.colspan-2 {
    width: 33.33333%;
  }
  &.table-cols-6 > .table-row > .table-col.colspan-3 {
    width: 49.99999%;
  }
  &.table-cols-6 > .table-row > .table-col.colspan-4 {
    width: 66.66666%;
  }
  &.table-cols-6 > .table-row > .table-col.colspan-5 {
    width: 83.33333%;
  }
  &.table-cols-6 > .table-row > .table-col.colspan-6 {
    width: 99.99999%;
  }

  &.table-cols-5 > .table-row > .table-col {
    width: 19.99999%;
  }
  &.table-cols-5 > .table-row > .table-col.colspan-2 {
    width: 39.99999%;
  }
  &.table-cols-5 > .table-row > .table-col.colspan-3 {
    width: 59.99999%;
  }
  &.table-cols-5 > .table-row > .table-col.colspan-4 {
    width: 79.99999%;
  }
  &.table-cols-5 > .table-row > .table-col.colspan-5 {
    width: 99.99999%;
  }

  &.table-cols-4 > .table-row > .table-col {
    width: 24.99999%;
  }
  &.table-cols-4 > .table-row > .table-col.colspan-2 {
    width: 49.99999%;
  }
  &.table-cols-4 > .table-row > .table-col.colspan-3 {
    width: 74.99999%;
  }
  &.table-cols-4 > .table-row > .table-col.colspan-4 {
    width: 99.99999%;
  }

  &.table-cols-3 > .table-row > .table-col {
    width: 33.33333%;
  }
  &.table-cols-3 > .table-row > .table-col.colspan-2 {
    width: 66.66666%;
  }
  &.table-cols-3 > .table-row > .table-col.colspan-3 {
    width: 99.99999%;
  }

  &.table-cols-2 > .table-row > .table-col {
    width: 49.99999%;
  }
  &.table-cols-2 > .table-row > .table-col.colspan-2 {
    width: 99.99999%;
  }

  &.table-cols-1 > .table-row > .table-col {
    width: 99.99999%;
  }
}


.boarding-table {
  &_time {
    font-weight: 600;
    font-size: 14px;
    padding: 0 10px 0 0;
  }
  &_ex {
    font-weight: 600;
    font-size: 14px;
  }

  &_delim {
    margin: 0 0 16px;
    border-radius: 0 0 4px 4px;
    height: 4px;
    background: #F4F6F9;
  }
  &_passenger {
    background: #f9fafc;
    padding-right: 6px !important;
  }

  &_transport {
    font-size: 14px;
    padding: 5px 0;
    font-weight: 500;

    &-wrap {
      background: #f4f6f9;
      line-height: normal;
      border-radius: 4px 4px 0 0;
    }
  }

  &_checkbox {
    padding: 0 10px 0 0;
  }

  &_place {
    font-weight: 500;
    font-size: 12px;
  }
}
